import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import "../src/assets/plugins/bootstrap/css/bootstrap.min.css";
import Login from "./components/auth/Login";
import Register from "./components/account/Register";
import Dashboard from "./components/auth/Dashboard";
import Navigation from "./components/navigation/nav";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import ResetPassword from "./components/account/resetpassword";
import { history } from "./helpers/history";
import ForgotPassword from "./components/account/ForgotPassword";
import IdentityVerification from "./components/account/IdentityVerification";
import TwoFAVerification from "./components/account/TwoFAVerification";
import Verification from "./components/verification/Verification";
import CardHolderSettings from "./components/cardholders/Settings";
import Help from "./components/Help";
import CardHolderCards from "./components/cardholderCardScreens/CardHolderCards";
import CardHolderTransactions from "./components/cardholderCardScreens/CardHolderTransactions";
import MobileMenu from "./helpers/MobileMenu";
import ThemeSelector from "./helpers/ThemeSelector";
import SendMoney from "./components/cardholderCardScreens/SendMoney";
import { useIdleTimer } from "react-idle-timer";
import PreviewTheme from "./components/previewTheme/PreviewTheme";
import { haspermissions } from "./components/permissions/PermissionTypes";
import CardVerification from "./components/account/CardVerification";
import RegisterGlobal from "./components/issuerGlobal/RegisterGlobal";
import AllDone from "./helpers/AllDone";
import DSecureCode from "./components/cardholders/3DSecureCode";
import RegisterIssuerT from "./components/issuerGlobal/RegisterIssuerT";

var primary;
var secondary;
var lighter1;
var lighter2;
var mainBg;
var menuBg;
var cardBg;
var selectedTab;
var tabHover;
var headingText;
var icon;
var line;
var textBox;
var font;
var defaultcolor="transparent";
const App = () => {
  const { user: currentUser } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  // const [idleTime, setIdleTime] = useState(process.env.REACT_APP_LOGOUT_TIME); //// time in mili seconds for 5 mints
  const [idleTime, setIdleTime] = useState(1000000);
  useEffect(() => {
    history.listen(location => {
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

  const logOut = () => {
    dispatch(logout());
    removeThemeVariables();
  };
  const addThemeVariables = () => {
    primary = currentUser?.user?.PrimaryColor;
    secondary = currentUser?.user?.SecondaryColor;
    lighter1 = currentUser?.user?.ColorPrimarylighter1;
    lighter2 = currentUser?.user?.ColorPrimarylighter2;
    mainBg = currentUser?.user?.MainBackgroundColor ?? defaultcolor;
    menuBg = currentUser?.user?.MenuBackgroundColor ?? "#ffffff";
    cardBg = currentUser?.user?.CardBackgroundColor?? "#121212";
    selectedTab = currentUser?.user?.SelectedTabColor?? "#161618";
    tabHover = currentUser?.user?.TabHoverColor??defaultcolor;
    headingText = currentUser?.user?.HeadingTextColor??"#161618";
    icon = currentUser?.user?.IconColor??"#161618";
    line = currentUser?.user?.LineColor??defaultcolor;
    textBox = currentUser?.user?.TextBoxColor??defaultcolor;
    font = currentUser?.user?.FontColor??defaultcolor;
    document.documentElement.style.setProperty("--color-primary", primary);
    document.documentElement.style.setProperty("--color-secondary", secondary);
    document.documentElement.style.setProperty("--color-primary-lighter-1", lighter1);
    document.documentElement.style.setProperty("--color-primary-lighter-2", lighter2);
    document.documentElement.style.setProperty("--color-main-bg", mainBg);
    document.documentElement.style.setProperty("--color-menu-bg", menuBg);
    document.documentElement.style.setProperty("--color-card-bg", cardBg);
    document.documentElement.style.setProperty("--color-selected-tab", selectedTab);
    document.documentElement.style.setProperty("--color-tab-hover", tabHover);
    document.documentElement.style.setProperty("--color-heading-text", headingText);
    document.documentElement.style.setProperty("--color-icon", icon);
    document.documentElement.style.setProperty("--color-line", line);
    document.documentElement.style.setProperty("--color-text-box", textBox);
    document.documentElement.style.setProperty("--color-font", font);
  };
  const removeThemeVariables = () => {
    document.documentElement.style.removeProperty("--color-primary", primary);
    document.documentElement.style.removeProperty("--color-secondary", secondary);
    document.documentElement.style.removeProperty("--color-primary-lighter-1", lighter1);
    document.documentElement.style.removeProperty("--color-primary-lighter-2", lighter2);
    document.documentElement.style.removeProperty("--color-main-bg",mainBg);
    document.documentElement.style.removeProperty("--color-menu-bg",menuBg);
    document.documentElement.style.removeProperty("--color-card-bg",cardBg);
    document.documentElement.style.removeProperty("--color-selected-tab",selectedTab);
    document.documentElement.style.removeProperty("--color-tab-hover",tabHover);
    document.documentElement.style.removeProperty("--color-heading-text",headingText);
    document.documentElement.style.removeProperty("--color-icon",icon);
    document.documentElement.style.removeProperty("--color-line",line);
    document.documentElement.style.removeProperty("--color-text-box",textBox);
    document.documentElement.style.removeProperty("--color-font",font);
  };
  if (currentUser) {
    haspermissions.CardToCard = currentUser?.user?.Permissions?.includes("Card to Card Transfer");
    haspermissions.UserKyc = currentUser?.user?.Permissions?.includes("User KYC");
    addThemeVariables();
  }
  const handleOnIdle = (event) => {
    logOut();
  };
  const handleOnActive = (event) => {
    console.log("user is active", event);
    console.log("time remaining", getRemainingTime());
  };

  const handleOnAction = (event) => {
    setIdleTime(idleTime);
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: idleTime,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    crossTab: true,
    debounce: 500,
  });
  return (
    <ThemeSelector>
      <Router history={history}>
        {currentUser ? (
          <div className="bo-app-main">
            {/* header */}
            <header className="header-main-outer">
              <section className="header-main">
                <div className="row align-items-center position-relative position-md-static">
                  <div className="col-12 p-static d-flex align-items-center justify-content-center">
                    <div className="div-hamburger">
                      <button
                        className="hamburgers"
                        type="button"
                        onClick={() => {
                          MobileMenu();
                        }}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                      </button>
                    </div>
                    <div className="logo d-flex align-items-center bo-thumb">
                      <Link to="#">
                        <h3>
                          Card<span>Console</span>
                        </h3>
                      </Link>
                    </div>
                  </div>
                </div>
              </section>
            </header>
            <div className="row minh-row">

              {(currentUser?.user?.IsCardActivate) && (
                <Fragment>
                  {
                    (!currentUser?.user?.IsKycSubmitted && currentUser?.user?.IssuerName === "IssuerG") ? ("") : 
                    (<Navigation
                      username={currentUser.user.Name}
                      role={currentUser.role}
                      logo={currentUser.user.ClientLogoUrl}
                      logOut={logOut}
                      sendmoneypermission={haspermissions.CardToCard}
                      isphysicalcard={currentUser?.user?.IsPhysicalCard}
                      clientId={currentUser?.user?.ClientId}
                      userkyc={currentUser?.user?.Permissions?.includes("User KYC")}
                      iskycsubmitted={currentUser?.user?.IsKycSubmitted}
                      KycApprovedByIssuer={currentUser?.user?.KycApprovedByIssuer}
                      IssuerName={currentUser?.user?.IssuerName}
                    />)
                  }
                  {(haspermissions.UserKyc && !currentUser?.user?.IsKycSubmitted &&(currentUser?.user?.KycApprovedByIssuer===null ||currentUser?.user?.KycApprovedByIssuer==="No")) ?
                    (<>
                      <Switch>
                        <Route exact path={["/", "/identityVerification"]} component={IdentityVerification} />
                        <Route
                          exact
                          path="/identityVerification"
                          component={IdentityVerification}
                        />
                        <Redirect to={"/identityVerification"}/>

                      </Switch>
                    </>) : 
                    (haspermissions.UserKyc && currentUser?.user?.IsKycSubmitted &&(currentUser?.user?.KycApprovedByIssuer===null ||currentUser?.user?.KycApprovedByIssuer==="No"))
                    ?(
                      <>
                      <Switch>
                        <Route exact path={["/", "/identityVerification"]} component={IdentityVerification} />
                        <Route
                          exact
                          path="/identityVerification"
                          component={IdentityVerification}
                        />
                         <Redirect to={"/identityVerification"}/>

                      </Switch>
                    </>
                    ):
                    (
                      <>
                        <Switch>

                          <Route exact path={["/", "/dashboard"]} component={Dashboard} />
                          <Route exact path="/dashboard" component={Dashboard} />

                          <Route
                            exact
                            path="/cardHolder/settings"
                            component={CardHolderSettings}
                          />
                          <Route exact path="/help" component={Help} />
                          <Route
                            exact
                            path="/cardholdercards"
                            component={CardHolderCards}
                          />
                          <Route
                            exact
                            path="/cardholdertransactions"
                            component={CardHolderTransactions}
                          />
                           <Route
                            exact
                            path="/3dcode"
                            component={DSecureCode}
                          />
                          {
                            haspermissions?.CardToCard && (

                              <Route exact path="/sendmoney" component={SendMoney} />

                            )
                          }
                          <Route
                            exact
                            path="/identityVerification"
                            component={IdentityVerification}
                          />

                        </Switch>
                      </>
                    )}

                </Fragment>
              )}
              {!currentUser?.user?.IsCardActivate &&

                <Switch>
                  {
                    ((currentUser?.user?.IssuerName === "IssuerG" ||currentUser?.user?.IssuerName === "IssuerT")  && currentUser?.user?.KycApprovedByIssuer == null) ? (
                      <Route exact path={["/", "/dashboard"]} component={Dashboard} />
                    ) : (
                      <Route exact path={["/", "/cardverification"]} component={CardVerification} />
                    )
                  }

                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route
                    exact
                    path="/cardverification"
                    component={CardVerification} />
                  <Route
                    exact
                    path="/identityVerification"
                    component={IdentityVerification}
                  />
                  <Route
                    exact
                    path="/alldone"
                    component={AllDone} />
                </Switch>
              }

            </div>
          </div>
        ) : (
          <section className="bo-form-screens">
            <div className="container-fluid">
              <Switch>
                <Route exact path={["/", "/Login"]} component={Login} />
                <Route exact path="/login" component={Login} />
                <Route
                  exact
                  path="/forgotpassword"
                  component={ForgotPassword}
                />
                <Route exact path="/resetpassword" component={ResetPassword} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/registerglobal" component={RegisterGlobal} />
                <Route
                  exact
                  path="/identityVerification"
                  component={IdentityVerification}
                />
                <Route
                  exact
                  path="/2FAVerification"
                  component={TwoFAVerification}
                />
                <Route exact path="/verification" component={Verification} />
                <Route exact path="/previewtheme" component={PreviewTheme} />
                {/* <Route
                  exact
                  path="/cardverification"
                  component={CardVerification} /> */}
                <Route
                  exact
                  path="/alldone"
                  component={AllDone} />
                  <Route
                  exact
                  path="/kyccustomer"
                  component={RegisterIssuerT} />
                <Redirect to={"/login"} />

              </Switch>
            </div>
          </section>
        )}
      </Router>
    </ThemeSelector>
  );
};

export default App;
